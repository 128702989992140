@import url("https://use.typekit.net/bpu5dur.css");

:root {
  --primary-color: #3e8bd2;
  --primary-color-light: #3e8bd233;
  --secondary-color: #01214f;
  --accent-color: #9ed642;
}

#username-helper-text,
#password-helper-text {
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: 700 !important;
}
#username-helper-text > p,
#password-helper-text > p {
  color: #da2902;
}

.account-status-btn {
  height: 23px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  text-transform: capitalize !important;
  color: #000000 !important;
  border-radius: 4px !important;
  min-width: 70px;
}

.account-status-btn.active {
  background-color: #dbff8e !important;
  border: 1px solid #40aa0e !important;
}

.account-status-btn.inactive {
  background-color: #ffd7d7 !important;
  border: 1px solid #aa210e !important;
}

.account-status-btn.suspended {
  background-color: #ffcb8e !important;
  border: 1px solid #aa210e !important;
}

.sadm-dashboard-menu-item {
  color: white;
}

.sadm-dashboard-menu-item.active {
  font-weight: 700 !important;
  color: white;
}
.sadm-dashboard-menu-item.active span {
  font-weight: 700 !important;
}

.sadm-dashboard-menu-item:hover {
  color: white;
  cursor: pointer;
}

.account-menu-item span {
  font-size: 15px;
}

.account-menu-item.active span {
  color: var(--primary-color);
  font-weight: 700 !important;
}
